function pc() {

	var headerHeight = $(window).outerHeight();
	var urlHash = location.hash;
	if (urlHash) {
		window.scrollTo(0, 0);
		$('body,html').stop().scrollTop(0);
		setTimeout(function () {
			window.scrollTo(0, 0);
		}, 1800);


		setTimeout(function () {

			var target = $(urlHash);
			var position = target.offset().top - headerHeight;
			$('body,html').stop().animate({
				scrollTop: position
			}, 500);
		}, 2400);
	}


}

function pcMenu() {
	$('#menuBtn').on({
		'mouseenter': function () {

			console.log('マウスが重なった！');
			$(this).addClass('on');
			$('.headerNavi').addClass('show');
		}
	})

	$('.headerNavi').on({
		'mouseleave': function () {

			$(this).removeClass('on');
			$('.header__menuBtn').removeClass('on');
			$('.headerNavi').removeClass('show');

		}
	})
}

var windowWidth = $(window).width();
var windowSm = 767;
if (windowWidth <= windowSm) {
	console.log("spscript");
} else {

	pc();
	pcMenu();
	console.log("pcscript2");
}
